<template>
  <v-row class="ma-0 pa-0">
    <v-col class="ma-0 pa-0">
      <div
        class="vimeo-space"
        style="padding: 56.25% 0 0 0; position: relative;"
      >
        <v-card
          style="position:absolute;top:0px;z-index:5"
          v-if="contentSelection.visible"
        >
          <v-card-title>
            {{ contentSelection.title }}
          </v-card-title>
          <v-card-text>
            {{ contentSelection.description }}
          </v-card-text>
          <v-card-actions>
            <v-btn @click="play">Play</v-btn>
            <v-btn @click="pause">Pause</v-btn>
          </v-card-actions>
        </v-card>
        <Gallery
          v-if="collectionCard.length > 0"
          :collectionCard="collectionCard"
          style="position:absolute; top:0px;z-index:2;width:100%"
        ></Gallery>
        <iframe
          src="https://player.vimeo.com/video/427155451?api=1&background=1"
          style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;"
          frameborder="0"
          ref="sesionvimeo"
          id="sesionvimeo"
          width="100%"
          :height="`${heightFrame}px`"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </div>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable no-console */
import Player from '@vimeo/player'
import Gallery from '../components/sesion/Gallery.vue'
export default {
  components: {
    Gallery
  },
  data: () => {
    return {
      collectionCard: [],
      heightFrame: 400,
      cardText: false,
      contentSelection: {
        title: '',
        description: '',
        visible: false
      },
      player_vimeo: {},
      volume_current: 0
    }
  },
  methods: {
    cardSetTimeout () {
      console.log('paso')
      this.contentSelection.visible = false
    },
    onResize () {
      this.heightFrame =
        ((document.querySelector('#sesionvimeo').offsetWidth - 50) * 9) / 16
    },
    pause () {
      this.player_vimeo.pause()
    },
    mute () {
      this.volume_current = this.player_vimeo.getVolume()
      this.player_vimeo.setVolume(0)
    },
    play () {
      this.player_vimeo.setVolume(this.volume_current)
      this.player_vimeo.play()
    }
  },
  async created () {
    this.collectionCard = (
      await this.$http.get('/Videos/Certificacion/-1')
    ).data
  },
  mounted () {
    document.querySelector('.zsiq_floatmain.zsiq_theme8.siq_bR').style.display =
      'none'
    this.onResize()
    // console.log(this.$router.currentRoute.name)
    this.player_vimeo = new Player(this.$refs.sesionvimeo)
    // player.on('play', function () {
    //   console.log('Played the video')
    // })
    this.player_vimeo.setAutopause(true)
    let arrayCuePoint = []

    // player.getVideoTitle().then(function (title) {
    //   console.log('title:', title)
    // })
    let arrayContenido = [
      {
        title: 'Questions 1',
        description: 'This is description of the questions',
        seconds: 2,
        durations: 3,
        type_game: 1,
        customKey: 'customKey',
        actions: []
      }
    ]
    arrayContenido.forEach(ele => {
      this.player_vimeo
        .addCuePoint(ele.seconds, {
          customKey: ele.customKey
        })
        .then(function (id) {
          // The cue point is added
          console.log(id)
          arrayCuePoint.push({
            id,
            ...ele
          })
        })
        .catch(function (error) {
          switch (error.name) {
            case 'UnsupportedError':
              // Cue points aren't supported by the current player or browser
              break

            case 'RangeError':
              // The time is less than 0 or greater than the video's duration
              break

            default:
              // Some other error occurred
              break
          }
        })
    })

    this.player_vimeo.on('cuepoint', data => {
      console.log(data)
      this.cardText = true
      arrayCuePoint.forEach(element => {
        console.log(element)
        if (element.id == data.id) {
          this.contentSelection = {
            title: element.title,
            description: element.description,
            visible: true
          }
          element.actions.forEach(ele => {
            this[ele]()
          })
          if (element.durations !== -1) {
            setTimeout(() => {
              this.cardSetTimeout()
            }, element.durations * 1000)
          }
        }
      })
    })

    // player.on('timeupdate', data => {
    //   console.log(data)
    // })
    // player.on('seeked', data => {
    //   console.log(data)
    // })
    this.player_vimeo
      .getCuePoints()
      .then(function (cuePoints) {
        // `cuePoints` indicates an array of cue point objects
        console.log(cuePoints)
      })
      .catch(function (error) {
        switch (error.name) {
          case 'UnsupportedError':
            // Cue points aren't supported by the current player or browser
            break

          default:
            // Some other error occurred
            break
        }
      })
    this.player_vimeo.on('ended', function (data) {
      // `data` is an object containing properties specific to that event
      console.log(data)
      if (data.percent == 1) {
        alert('Cambiar al video 2')
      }
    })
  }
}
</script>
