<template>
  <v-container>
    <v-card max-width="300"
            max-height="300"
            class="mx-auto"
            justify="center"
            align="center"
            color="grey lighten-2--text light-green lighten-1">
    <div class="timer"><h2>{{ time | prettify }}</h2> </div>
    </v-card>
  </v-container>
</template>
<script>

export default {
  props: ["time"],
  
  filters: {
    prettify: function(value) {
      // console.log(value)
      let data = value.split(":")
      let minutes = data[0]
      let secondes = data[1]
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      if (secondes < 10) {
        secondes = "0" + secondes
      }
      return minutes +":"+ secondes
    },
  },
};
</script>
