<template>
  <v-row>
    <v-col>
      <!--aca tengo mi tabla de imagenes-->
      <v-row justify="center" align="center">
        <!--tablero-->
        <v-col cols="11">
          <v-card
            class="mx-auto"
            justify="center"
            align="center"
            color="amber lighten-1"
            elevation="3"
          >
            <v-row>
              <v-col>
                <v-card
                  class="mx-auto"
                  max-width="300"
                  justify="center"
                  align="center"
                  v-show="modalMessagePositive"
                  elevation="3"
                  light
                >
                  <v-img
                    class="white--text align-end"
                    height="150px"
                    :src="require(`../../assets/${messageSelected.img}`)"
                    max-width="150px"
                  >
                    <v-card-title v-html="messageSelected.title"></v-card-title>
                  </v-img>
                  <v-card-subtitle
                    class="pb-0"
                    v-html="messageSelected.bajada"
                  ></v-card-subtitle>
                  <v-card-text
                    light
                    class="text--primary"
                    v-html="messageSelected.description"
                  >
                  </v-card-text>
                </v-card>
                <div v-show="!modalMessagePositive">
                  <h3>Intentos Fallidos:</h3>
                  <h2>{{ intentos }}</h2>
                  <h3>
                    Aciertos:
                  </h3>
                  <h2>{{ aciertos }}</h2>
                </div>
              </v-col>
              <v-col>
                <div v-if="time">
                  <timer :time="prettyTime"></timer>
                </div>
                <h3>Puntaje:</h3>
                <h1>{{ puntaje }}</h1>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      value="iniciar"
                      :disabled="modalLoadBoard"
                      :loading="modalLoadBoard"
                      class="ma-2"
                      outlined
                      color="black"
                      v-show="!isRunnig && mostrarjugar"
                      @click="modalLoadBoard = true"
                      v-on:click="iniciarJuego"
                      v-bind="attrs"
                      v-on="on"
                      >jugar</v-btn
                    >
                  </template>
                  <span> se iniciara el juego </span>
                </v-tooltip>
                <div v-if="time">
                  <v-btn
                    class="ma-2"
                    outlined
                    color="black"
                    tile
                    v-if="!isRunnig && mostrarStart"
                    @click="start"
                    >Start</v-btn
                  >
                  <v-btn
                    class="ma-2"
                    tile
                    outlined
                    color="black"
                    @click="pause"
                    v-if="isRunnig"
                    >Pausar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <v-card elevation="3" max-height="600" color="grey lighten-3">
            <v-img
              src="../../assets/cert/mosaico/memorama.jpg"
              gradient="to top right, rgba(100,115,201,.33), rgba(55,72,72,.9)"
              height="400"
            >
              <v-responsive :aspect-ratio="16 / 9">
                <v-main id="juego">
                  <v-table-overflow
                    justify="center"
                    align="center"
                    v-if="isRunnig"
                  >
                    <v-row>
                      <v-col
                        cols="2"
                        v-for="(carta, index) in cartas"
                        :key="index"
                        v-on:click="girarCarta(carta)"
                        class="letra"
                      >
                        <v-img
                          transition="scroll-x-transition"
                          v-if="!carta.visible"
                          src="../../assets/cert/mosaico/MISTERIO.png"
                          max-width="90px"
                        />
                        <v-img
                          v-else
                          :src="require('../../assets/' +carta.valor)"
                          max-width="95px"
                          transition="scroll-x-transition"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-table-overflow>
                  <v-row>
                    <v-col>
                      <v-spacer></v-spacer>
                    </v-col>
                  </v-row>
                </v-main>
              </v-responsive>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <!--aca tengo mis Botones-->
      <v-row justify="center" align="center">
        <v-dialog v-model="modalLoadBoard" hide-overlay persistent width="300">
          <v-card color="secondary" dark>
            <v-card-text>
              Cargando Tablero .....
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="modalCardSeleccionada"
          hide-overlay
          persistent
          width="200"
        >
          <v-card flat tile>
            <v-alert type="warning" max-height="200" max-width="300">
              Carta ya seleccionada
            </v-alert>
            <v-card-text justify="center" align="center">
              Intente nuevamente
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="modalCardDistinta"
          hide-overlay
          persistent
          width="200"
        >
          <v-card flat tile>
            <v-alert type="error" max-height="200" max-width="300">
              Son distintas
            </v-alert>
            <v-card-text justify="center" align="center">
              vuelve a intentarlo
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="modalMensajePositivo"
          hide-overlay
          persistent
          width="200"
        >
          <v-card flat tile>
            <v-alert type="success">
              Excelente
            </v-alert>
            <v-card-text>
              sigue asi
            </v-card-text>
          </v-card>
        </v-dialog>

        <div class="text-center">
          <v-dialog
            v-model="modalMessageWinner"
            hide-overlay
            persistent
            max-width="800px"
            max-height="800px"
          >
            <v-card color="white" dark justify="center" align="center">
              <v-img
                src="../../assets/cert/mosaico/winer.png"
                max-width="150px"
                justify="center"
                align="center"
              />
              <v-img
                src="../../assets/cert/mosaico/ganador.gif"
                max-width="150px"
                justify="center"
                align="center"
              >
              </v-img>
              <h1 class="black">Puntaje Total: {{ puntaje }} pts</h1>

              <v-btn class="ma-2" outlined color="black" @click="finjuego">
                Jugar de nuevo
              </v-btn>
              <v-card-text
                class="light-green darken-1 - grey--text text--lighten-5"
              >
                sigue jugando nuestros proximos niveles
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <v-dialog
          v-model="modalGameOver"
          hide-overlay
          persistent
          width="400"
          justify="top"
          align="top"
        >
          <v-card color="white" dark justify="center" align="center">
            <v-img
              src="../../assets/cert/mosaico/gameover.png"
              max-width="150px"
            >
            </v-img>
            <v-card-title
              class="blue lighten-2"
              justify="center"
              align="center"
            >
              Tu tiempo se ha agotado
            </v-card-title>

            <v-card-text
              class="light-green darken-1 - grey--text text--lighten-5"
            >
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="modalGameInPause"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card justify="center" align="center">
            <v-card
              max-width="600"
              max-height="600"
              class="mx-auto"
              justify="center"
              align="center"
              color="light-green lighten-1"
            >
              <h1 class="white--text darken-1">JUEGO EN PAUSA</h1>
            </v-card>
            <v-img src="../../assets/cert/mosaico/pausa.gif" max-width="600px">
            </v-img
            ><br />
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="start"
              v-show="!isRunnig"
            >
              REANUDAR
            </v-btn>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-console */
let tick = new Audio(require('../../assets/cert/mosaico/tick.mp3'))
let gcarta = new Audio(require('../../assets/cert/mosaico/gcarta.mp3'))
let incorrecto = new Audio(require('../../assets/cert/mosaico/incorrecto.mp3'))
let win = new Audio(require('../../assets/cert/mosaico/win.mp3'))
let lose = new Audio(require('../../assets/cert/mosaico/lose.mp3'))
let idea = new Audio(require('../../assets/cert/mosaico/idea.mp3'))

import Crono from './Crono.vue'
export default {
  props: {
    collectionCard: {
      type: Array,
      default: []
    }
  },
  components: {
    timer: Crono
  },
  //uso data para relacionarlo con v-for
  data () {
    return {
      mostrarStart: false,
      mostrarjugar: true,
      modalLoadBoard: false,
      modalCardDistinta: false,
      modalMensajePositivo: false,
      modalMessageWinner: false,
      modalGameOver: false,
      modalMessagePositive: false,
      modalCardSeleccionada: false,
      modalGameInPause: false,
      isRunnig: false,
      isPaused: false,
      seleccion1: false,
      seleccion2: false,
      minutes: 0,
      secondes: 0,
      time: 0,
      timer: null,

      //arreglo de bloques
      intentos: 0,
      aciertos: 0,
      puntaje: 0,
      jugada1: '',
      jugada2: '',
      identificadorJ1: '',
      identificadorJ2: '',
      relation1: '',
      relation2: '',
      visible1: '',
      visible2: '',
      cartas: [],
      messagesPositive: [
        {
          img: 'cert/mosaico/notas/nota2.jpg',
          title: 'Sabias que?',
          bajada: 'dato curioso',
          description: `Los conejitos viven en grupos cuando están en su ambiente
                  natural. ...<br/> Pueden llegar a vivir más de 10 años...`
        },
        {
          img: 'cert/mosaico/notas/nota3.jpg',
          title: 'Sabias que?',
          bajada: 'dato curioso',
          description: `Hay más canguros que humanos en Australia.....<br/> 
                  Los canguros son animales sociales y andan siempre en
                  grupos....`
        },
        {
          img: 'cert/mosaico/notas/nota1.jpg',
          title: 'Sabias que?',
          bajada: 'dato curioso',
          description: `El Segundo Gato Más Grande<br/> 
                  Los Leones No Viven en la Selva.`
        }
      ],
      messageSelected: {
        img: 'cert/mosaico/notas/nota2.jpg',
        title: '',
        bajada: '',
        description: ''
      }
      //arreglo de imagenes del juego y las correlaciono con su par igual
    }
  },
  created () {
    try {
      this.colecciondecartas = [...this.collectionCard]
      console.log(this.colecciondecartas)

     
      this.time = this.colecciondecartas[0].time
      console.log(this.colecciondecartas)
    } catch (e) {
      console.error(e)
    }
  },

  watch: {
    modalLoadBoard (val) {
      if (!val) return
      setTimeout(() => (this.modalLoadBoard = false), 2000)
    },

    modalCardDistinta (val) {
      if (!val) return
      setTimeout(() => (this.modalCardDistinta = false), 1000)
    },

    modalMensajePositivo (val) {
      if (!val) return
      setTimeout(() => (this.modalMensajePositivo = false), 1000)
    },
    modalMessageWinner (val) {
      if (!val) return
    },
    modalGameOver (val) {
      if (!val) return
      setTimeout(() => (this.modalGameOver = false), 4000)
    },
    modalMessagePositive (val) {
      if (!val) return
      setTimeout(() => (this.modalMessagePositive = false), 4000)
    },
    modalCardSeleccionada (val) {
      if (!val) return
      setTimeout(() => (this.modalCardSeleccionada = false), 2000)
    },
    modalGameInPause (val) {
      if (!val) return
    }
  },

  computed: {
    prettyTime () {
      let time = this.time / 60
      let minutes = parseInt(time)
      let secondes = Math.round((time - minutes) * 60)
      return minutes + ':' + secondes
      // return ''
    }
  },

  methods: {
    start () {
      this.modalGameInPause = false
      this.isRunnig = true
      this.mostrarStart = true
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            clearInterval(this.timer)
            this.reset()
          }
        }, 1000)
      }
    },
    stop () {
      this.isRunnig = false
      clearInterval(this.timer)
      this.timer = null
    },
    reset () {
      lose.play()
      this.modalGameOver = true
      this.mostrarStart = false
      this.modalGameInPause = false
      this.stop()
      this.time = this.colecciondecartas[0].time
      this.secondes = 0
      this.minutes = 0
      this.aciertos = 0
      this.puntaje = 0
      this.intentos = 0
    },
    finjuego () {
      this.time = this.colecciondecartas[0].time
      this.secondes = 0
      this.minutes = 0
      this.resetearjuego()
      this.modalMessageWinner = false
      this.mostrarjugar = true
      this.mostrarStart = false
      this.modalGameInPause = false
    },
    pause () {
      this.modalGameInPause = true
      this.stop()
      this.mostrarjugar = false
    },

    iniciarJuego () {
      console.log(this.colecciondecartas)
      this.time = this.colecciondecartas[0].time

      this.cartas = []
      let cantidadDePares = this.colecciondecartas[0].quantity_pair
      let colecciondecartas = [...this.colecciondecartas]
      console.group('Start')
      for (let i = 0; i < cantidadDePares; i++) {
        let elemento = colecciondecartas.splice(0, 1)
        this.cartas.push({
          valor: elemento[0].img,
          id: elemento[0].pair_card,
          relation: elemento[0].pair_card_idguid,
          seleccion: elemento[0].selection == 0 ? false : true,
          visible: false
        })
        let index = colecciondecartas.findIndex(
          ele => ele.pair_card_idguid == elemento[0].pair_card
        )
        let elementoRelacionado = colecciondecartas.splice(index, 1)
        this.cartas.push({
          valor: elementoRelacionado[0].img,
          id: elementoRelacionado[0].pair_card,
          relation: elementoRelacionado[0].pair_card_idguid,
          seleccion: elementoRelacionado[0].selection == 0 ? false : true,
          visible: false
        })
      }
      console.groupEnd('Start')
      //muestro el array cartas
      this.cartas.sort(function () {
        return Math.random() - 0.5
      })
      console.log(this.cartas)
      this.intentos = 0
      this.aciertos = 0
      this.puntaje = 0
      this.start()
    },
    //reseteo el juego genera otra matriz random

    resetearjuego () {
      this.vaciar()
      this.cartas.forEach(ele => {
        ele.visible = false
      })
      this.intentos = 0
      this.aciertos = 0
      this.puntaje = 0
    },
    //aca hago el cambio de carta , tiene problemas !!!!!!!!!!!!!!!!

    girarCarta (cartas) {
      gcarta.play()
      // si hago un click
      if (this.jugada1 !== '') {
        cartas.visible = true
        this.jugada2 = cartas
        this.identificadorJ2 = cartas.id
        this.relation2 = cartas.relation
        this.visible1 = cartas.visible
        if (this.jugada1 === this.jugada2) {
          idea.play()
          console.log('carta ya seleccionada')
          this.modalCardSeleccionada = true
          this.jugada2 = null
        } else {
          if (
            this.relation1 == this.identificadorJ2 &&
            this.identificadorJ1 == this.relation2 &&
            this.jugada1.seleccion == false &&
            this.jugada2.seleccion == false
          ) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.modalMensajePositivo = true
                tick.play()
                console.log('son iguales')
                this.jugada1.seleccion = true
                this.jugada2.seleccion = true
                cartas.visible = true
                this.aciertos = this.aciertos + 1
                this.puntaje = this.puntaje + 25
                if (this.aciertos == this.colecciondecartas[0].quantity_pair) {
                  this.pause()
                  this.modalMensajePositivo = false
                  win.play()
                  this.modalMessageWinner = true
                  this.mostrarStart = false
                  this.modalGameInPause = false
                }
                for (let i = 0; i < 17; i++) {
                  if (cartas.seleccion === false) {
                    cartas.visible = false
                  }
                }
                this.vaciar()
              }, 300)
            })
          } else {
            if (
              this.jugada2.seleccion == true &&
              this.jugada1.seleccion == false
            ) {
              idea.play()
              console.log('carta ya seleccionada')
              this.modalCardSeleccionada = true
              this.jugada2 = null
              console.log('jugada2')
            } else {
              this.$nextTick(() => {
                setTimeout(() => {
                  this.modalCardDistinta = true
                  incorrecto.play()
                  console.log('son distintas')
                  this.jugada1.visible = false
                  this.jugada2.visible = false
                  this.intentos = this.intentos + 1
                  if (this.intentos % 3 == 0) {
                    idea.play()
                    this.modalMessagePositive = true
                    let calcularCiclo =
                      parseFloat(this.intentos) / parseFloat(3) / 3
                    calcularCiclo -= 0.1
                    let indice =
                      Math.floor(this.intentos / 3) -
                      Math.floor(calcularCiclo) * 3

                    this.messageSelected = this.messagesPositive[indice - 1]
                    // console.log(
                    //   this.messageSelected,
                    //   indice,
                    //   calcularCiclo,
                    //   this.intentos / 3,
                    //   this.intentos
                    // )
                  }
                  for (let i = 0; i < 17; i++) {
                    if (cartas.seleccion === false) {
                      cartas.visible = false
                    }
                  }
                  this.vaciar()
                  //aca visible false en dos milisegundos
                }, 300)
              })
            }
          }
        }
        //si solo juego una vez (un click)
      } else {
        this.jugada1 = cartas
        this.jugada1.visible = true
        this.visible1 = true
        this.identificadorJ1 = cartas.id
        this.relation1 = cartas.relation
        if (this.jugada1.seleccion == true) {
          idea.play()
          console.log('carta ya seleccionada')
          this.modalCardSeleccionada = true
          this.jugada1 = null
          this.vaciar()
        } else {
          //mantengo la primera cartas visible a la espera de la segunda jugada
          console.log('se giro la carta')
          console.log('jugada1')
          gcarta.play()
          if (!cartas.visible) {
            cartas.visible = true
          }
        }
      }

      console.log(this.jugada1, this.jugada2)
    },

    //metodo que hace visible la cartas

    //metodo que me hace visible la cartas misterio

    // eslint-disable-next-line no-unused-vars
    vaciar (cartas) {
      //funcion para vaciar mis dos movimientos
      this.jugada1 = ''
      this.jugada2 = ''
      this.identificadorJ1 = ''
      this.identificadorJ2 = ''
      this.relation1 = ''
      this.relation2 = ''
    }
  }
}
</script>
